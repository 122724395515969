import styled from "styled-components";
import img from "../../Images/Pattern 4.png";

export const InfoContainer = styled.div`
  color: #fff;
  background-color: #1a2142;
  padding-top: 100px;
  min-height: 1300px;
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const Col1 = styled.div`
  padding-right: 50px;
`;

export const Col2 = styled.div`
  padding: 0 15px;
`;

export const InfoRow = styled.div`
  display: grid;

  grid-template-columns: 50% 50%;
  width: 100%;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  margin-left: 50px;
`;

export const TopLine = styled.p`
  color: #ffffff;
  font-size: 17px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media screen and (max-width: 515px) {
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width: 350px) {
    font-size: 13px;
    font-weight: 500;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;

  object-fit: contain;
  object-position: 50% 50%;
`;

export const ImageWraper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  height: 100%;
`;

export const Heading = styled.h1`
  color: #ff5412;
  margin-bottom: 24px;
  font-size: 50px;
  line-height: 1.1;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Quicksand", sans-serif;
  color: ${({ lightText }) => (lightText ? "#f9f9f9" : "010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

  @media screen and (max-width: 415px) {
    font-size: 28px;
  }

  @media screen and (max-width: 365px) {
    font-size: 23px;
  }
`;

export const Subtitle = styled.p`
  color: #ccced5;
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
`;
export const Subtitle2 = styled.p`
  color: #000;
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
`;
