import React from 'react'
import {Placas, Container, Text} from './StickyElements.js'

const StickyAd = () => {
  return (
    <Container>
        <Placas >
          <Text>Energia renovável</Text>
        </Placas>
    </Container>
    
  )
}

export default StickyAd