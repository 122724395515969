import img1 from "../../Images/casa3.jpg";
import img2 from "../../Images/agricola.jpg";
import img3 from "../../Images/industries.jpg";
import img4 from "../../Images/industria2.jpg";

const ServicesData = [
  {
    img: img1,
    alt: "teste1",
    name: "Residenciais",
    desc: "Casas, condomínios e prédios",
  },

  {
    img: img4,
    alt: "teste1",
    name: "Empresas",
    desc: "E comércios em geral",
  },

  {
    img: img2,
    alt: "teste1",
    name: "Agronegócios",
    desc: "Lazer, pecuária e agricultura",
  },

  {
    img: img3,
    alt: "teste3",
    name: "Indústrias",
    desc: "De todos os portes",
  },
];

export default ServicesData;
