import img1 from './ProjectImages/Case1.JPG'
import img2 from './ProjectImages/Case2.JPG'
import img3 from './ProjectImages/Case3.JPG'
import img4 from './ProjectImages/Case4.JPG'
import img5 from './ProjectImages/Case5.JPG'
import img6 from './ProjectImages/Case6.jpg'
import img7 from './ProjectImages/Case7.jpg'
import img8 from './ProjectImages/Case8.JPG'


const ProjectsData = [
    {
        id: "1",
        Image: img1,
        heading: "Prédio comercial",
        span: " Goiânia - GO",
        amount: " 23,98 kWp de potência",
        plates: " 44 painéis instaladas",
        sistema: " Sistema comercial",
        lucro: " R$ 35.900,00 de economia atual",
        geracao: " 3.190 kWh/mês em geração",
    },

    {
        id: "2",
        Image: img2,
        heading: "Condomínio Jardins Atenas",
        sistema: " Sistema residencial",
        span: " Goiânia - GO",
        amount: " 12,21 kWp de potência",
        plates: " 22 painéis instaladas",
        lucro: " R$ 17.944,00 de economia atual",
        geracao: " 1.567,11 kWh/mês em geração",
    },

    {
        id: "3",
        Image: img3,
        heading: "Fazenda Barreirinha",
        sistema: " Sistema rural",
        span: " Anápolis - GO",
        amount: " 10,26 kWp de potência",
        plates: " 19 painéis instaladas",
        lucro: " R$ 8.306,00 de economia atual",
        geracao: " 1.314,12 kWh/mês em geração",
    },

    {
        id: "4",
        Image: img4,
        heading: "Escola Caminho para o Futuro",
        sistema: " Sistema comercial",
        span: " Goiânia - GO",
        amount: " 16,20 kWp de potência",
        plates: " 30 painéis instaladas",
        lucro: " R$ 20.436,96 de economia atual",
        geracao: " 2.135,00 kWh/mês em geração",
    },

    {
        id: "5",
        Image: img5,
        heading: "Condomínio Meridian",
        sistema: " Sistema residencial",
        span: " Goianésia - GO",
        amount: " 8,33 kWp de potência",
        plates: " 15 painéis instaladas",
        lucro: " R$ 10.450,13 de economia atual",
        geracao: " 1.088,55 kWh/mês em geração",
    },

    {
        id: "6",
        Image: img6,
        heading: "Cliente residencial",
        sistema: " Sistema residencial",
        span: " Abadia de Goiás - GO",
        amount: " 7,63 kWp de potência",
        plates: " 14 painéis instaladas",
        lucro: " R$ 10.000,00 de economia atual",
        geracao: " 1.013,23 kWh/mês em geração",
    },

    {
        id: "7",
        Image: img7,
        heading: "Bel Fiore Garden Center",
        sistema: " Sistema comercial",
        span: " Goiânia - GO",
        amount: " 17,44 kWp de potência",
        plates: " 32 painéis instaladas",
        lucro: " R$ 20.795,00 de economia atual",
        geracao: " 2.175,61 kWh/mês em geração",
    },

    {
        id: "8",
        Image: img8,
        heading: "Usina Caicó 1",
        sistema: " Sistema comercial",
        span: " Goiânia - GO",
        amount: " 31,86 kWp de potência",
        plates: " 59 painéis instaladas",
        lucro: " R$ 30.000,00 de economia atual",
        geracao: " 4.000,00 kWh/mês em geração",
    }

]

export default ProjectsData;
